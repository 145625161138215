<template>
  <v-card
    :class="{
      'internet-plan-card': true,
      'selected-card': (plan === selectedPlan && !defaultPlan),
    }"
    :data-plan-id="plan.id"
    outlined
  >
    <v-card-title class="plan-name">
      {{ plan.name }}
    </v-card-title>
    <v-card-text class="plan-details">
      <div
        v-if="!isSpeedInName"
        class="speed"
      >
        {{ plan.download_speed }}{{ speedUnit }}
      </div>
      <div class="price">
        Ksh {{ plan.price }}
      </div>
      <div class="validity">
        {{ formatBillingInterval(plan.validity_unit, plan.validity) }}
      </div>
      <v-btn
        v-if="!defaultPlan"
        color="primary"
        class="choose-plan-button mb-8"
        outlined
        :disabled="isPlanSelected"
        @click="showConfirmationDialog(plan)"
      >
        Select Plan
      </v-btn>
    </v-card-text>
    <plan-change-dialog
      v-model="showUpdateInternetPlanDialog"
      :message="confirmationMessage"
      :is-agree-button-loading="isPlanBeingChanged"
      @plan-change="proceedWithPlanSelection"
    />
    <confirm-dialog
      :show-dialog="showPaymentConfirmation"
      :show-secondary-button="true"
      title="Payment Required for Plan Change"
      :message="`Due to your recent change in internet plan, an additional payment of Ksh ${paymentDebtAmount} is required. Please proceed with this payment to finalize your plan change.`"
      agree-text="Pay Now"
      cancel-text="Pay Later"
      secondary-button-text="Cancel Plan Change"
      @agree="confirmPayment"
      @cancel="payLater()"
      @secondary-button-clicked="cancelPlanSelection()"
    ></confirm-dialog>
    <mpesa-payment-dialog
      :show-dialog="showMpesaPaymentDialog"
      :amount="paymentDebtAmount"
      :contact-number="contactNumber"
      @close="showMpesaPaymentDialog = false"
    ></mpesa-payment-dialog>
  </v-card>
</template>

<script>
import axios from 'axios'
import billingIntervalName from '@/mixins/billingIntervalName'
import speedUnit from '@/mixins/speedUnit'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import PlanChangeDialog from '../dialogs/PlanChangeDialog.vue'
import MpesaPaymentDialog from '@/components/dialogs/InitiateMpesaPaymentDialog.vue'

export default {
  components: {
    ConfirmDialog,
    MpesaPaymentDialog,
    PlanChangeDialog,
  },
  mixins: [billingIntervalName, speedUnit],
  props: {
    plan: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    defaultPlan: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
      default: '',
    },
    customerPlanId: {
      type: String,
      default: '',
    },
    selectedPlan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showUpdateInternetPlanDialog: false,
      showPaymentConfirmation: false,
      isPlanBeingChanged: false,
      confirmationTitle: 'Confirm',
      confirmationMessage: 'Are you sure you want to change from your current plan {planName} to {toBeSelectedPlanName}?',
      showMpesaPaymentDialog: false,
      paymentDebtAmount: 0,
      contactNumber: null,
      toBeSelectedPlan: null,
      pendingPlanChangeId: null,
    }
  },
  computed: {
    isPlanSelected() {
      return this.plan.id === this.selectedPlan.id
    },
    isSpeedInName() {
      const speedWithUnit = this.plan.download_speed + this.speedUnit

      return this.plan.name.includes(speedWithUnit)
    },
  },
  methods: {
    showConfirmationDialog(plan) {
      this.toBeSelectedPlan = plan
      this.confirmationMessage = `You are about to change from your current internet plan, ${this.selectedPlan.name}, to ${plan.name}. Please select when you would like this plan change to take effect.`
      this.showUpdateInternetPlanDialog = true
    },
    proceedWithPlanSelection({ whenToChangePlan, specificDate }) {
      const requestBody = {
        new_internet_plan_id: this.toBeSelectedPlan.id,
        when_to_change_plan: whenToChangePlan,
        specific_date: specificDate,
      }
      this.isPlanBeingChanged = true

      axios.post('customer/internet-plan-update-schedule', requestBody)
        .then(response => {
          this.contactNumber = response.data.user.phone_number
          let debt = 0
          let successMessage = 'Your internet plan has been updated successfully.'

          if (response.data.pending_plan_change) {
            this.pendingPlanChangeId = response.data.pending_plan_change.id
            debt = parseFloat(response.data.pending_plan_change.amount_to_top_up)
          }

          if (whenToChangePlan === 'now') {
            this.$emit('plan-changed')
            if (debt > 0) {
              this.paymentDebtAmount = debt
              this.showPaymentConfirmation = true
            } else {
              this.$emit('plan-selected', this.toBeSelectedPlan)
              this.$toast.success('Plan changed successfully')
            }
          } else if (whenToChangePlan === 'after_current_plan_ends') {
            this.$emit('plan-change-scheduled')
            successMessage = 'Your internet plan will be updated after the current plan ends.'
            this.$toast.success(successMessage)
          } else if (whenToChangePlan === 'on_specific_date') {
            this.$emit('plan-change-scheduled')
            const formattedDate = this.$options.filters.formatDate(specificDate)
            successMessage = `Your internet plan will be updated on ${formattedDate}.`
            this.$toast.success(successMessage)
          }
        })
        .catch(error => {
          console.error('Error updating customer plan:', error)
          this.$toast.error(error.response.data.message)
          this.fetchCustomerCurrentPlan()
        }).finally(() => {
          this.isPlanBeingChanged = false
          this.showUpdateInternetPlanDialog = false
        })
    },
    cancelPlanSelection() {
      this.showPaymentConfirmation = false
      axios.post(`customer-plans/${this.pendingPlanChangeId}/cancel`)
        .then(() => {
          this.$emit('plan-changed')
          this.$emit('plan-selected', this.selectedPlan)
          this.$toast.success('Plan change cancelled successfully')
        })
        .catch(error => {
          console.error('Error cancelling plan change:', error)
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.showUpdateInternetPlanDialog = false
        })
    },
    confirmPayment() {
      this.showPaymentConfirmation = false
      this.toBeSelectedPlan = null
      this.showMpesaPaymentDialog = true
    },
    payLater() {
      this.showPaymentConfirmation = false
      this.$toast.success('Your plan change will be completed once you pay the pending amount')
    },
    cancelPlanSelectionDialog() {
      this.showUpdateInternetPlanDialog = false
    },
  },
}
</script>

<style scoped>
.internet-plan-card {
  transition: background-color 0.3s ease;
  border: 2px solid transparent;
  margin-bottom: 20px;
  padding: 15px 2px;
}

.internet-plan-card:hover {
  background-color: #ebebeb; /* Default color for light theme */

  /* Styles for dark theme */
  &.theme--dark:hover {
    background-color: #45425d;
  }
}

.selected-card {
  border-color: var(--v-primary-base) !important;
}

.plan-name {
  font-weight: bold;
  font-size: 20px;
  padding: 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-details {
  text-align: center;
  padding: 10px;
}

.price {
  font-weight: bold;
  margin-bottom: 5px;
}

.choose-plan-button {
  margin-top: 25px;
}
.speed {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}
</style>
