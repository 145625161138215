<template>
  <div class="internet-plans">
    <v-row>
      <v-col md="12">
        <bread-crumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-expand-transition>
      <PendingPlanChangeAlert ref="pendingPlanChangeAlertRef" />
    </v-expand-transition>
    <v-expand-transition>
      <ScheduledPlanChangeAlert ref="scheduledPlanChangeAlertRef" />
    </v-expand-transition>

    <v-row v-if="isInternetPlansLoading">
      <v-col
        cols="12"
        md="12"
      >
        <h2>Available Plans</h2>
      </v-col>
      <v-col
        v-for="index in 12"
        :key="index"
        md="3"
        sm="12"
        class="pa-0"
      >
        <internet-plan-shimmer />
      </v-col>
    </v-row>

    <div v-else>
      <template v-for="(plansGroup, type) in organizedPlans">
        <v-row
          v-if="plansGroup.length > 0"
          :key="type"
        >
          <v-col
            cols="12"
            md="12"
          >
            <h2>{{ type }} Plans</h2>
          </v-col>
          <v-col
            v-for="plan in plansGroup"
            :key="plan.id"
            md="3"
            sm="12"
          >
            <plan-card
              :plan="plan"
              :selected-plan="selectedPlan"
              :customer-id="customerId"
              :customer-plan-id="customerPlanId"
              @plan-selected="selectPlan"
              @plan-changed="onPlanChanged"
              @plan-change-scheduled="onPlanChangeScheduled"
            />
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import InternetPlanShimmer from '@/components/partials/shimmers/InternetPlanShimmer.vue'
import billingIntervalName from '@/mixins/billingIntervalName'
import PlanCard from '@/components/partials/PlanCard.vue'
import PendingPlanChangeAlert from '@/components/partials/PendingPlanChangeAlert.vue'
import ScheduledPlanChangeAlert from '@/components/partials/ScheduledPlanChangeAlert.vue'

export default {
  components: {
    BreadCrumb,
    InternetPlanShimmer,
    PlanCard,
    PendingPlanChangeAlert,
    ScheduledPlanChangeAlert,
  },
  mixins: [billingIntervalName],
  data() {
    return {
      isInternetPlansLoading: true,
      isCustomerCurrentPlanLoading: true,
      customerPlanId: null,
      customerId: null,
      selectedPlan: {},
      toBeSelectedPlan: null,
      menu: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Internet Plans',
          disabled: true,
          to: { name: 'internet-plans' },
        },
      ],
    }
  },
  computed: {
  // Organize plans by type dynamically
    organizedPlans() {
      const groupedPlans = {}
      this.plans.forEach(plan => {
        if (!groupedPlans[plan.type]) {
          groupedPlans[plan.type] = []
        }
        groupedPlans[plan.type].push(plan)
      })

      return groupedPlans
    },
  },
  created() {
    this.fetchInternetPlans()
  },
  methods: {
    fetchInternetPlans() {
      this.isInternetPlansLoading = true
      axios
        .get('customer/internet-plans')
        .then(response => {
          this.fetchCustomerCurrentPlan()
          const fetchedPlans = response.data.map(plan => {
            let type
            switch (plan.validity_unit) {
              case this.validityEnum.HOUR:
                type = 'Hourly'
                break
              case this.validityEnum.DAY:
                type = 'Daily'
                break
              case this.validityEnum.WEEK:
                type = 'Weekly'
                break
              case this.validityEnum.MONTH:
                type = 'Monthly'
                break
              case this.validityEnum.YEAR:
                type = 'Yearly'
                break
              default:
                console.error('Invalid validity unit:', plan.validity_unit)
                type = 'Unknown'
                break
            }

            // Return a new object for each plan including the determined type
            return { ...plan, type }
          })

          // Update the plans data property with the processed plans
          this.plans = fetchedPlans
        })
        .catch(error => {
          console.error('Error fetching internet plans:', error)
        })
        .finally(() => {
          this.isInternetPlansLoading = false
        })
    },
    fetchCustomerCurrentPlan() {
      this.isCustomerCurrentPlanLoading = true
      axios
        .get('customer-plans')
        .then(response => {
          this.selectPlan(response.data.internet_plan)
          this.customerId = response.data.customer_id
          this.customerPlanId = response.data.id
        })
        .catch(error => {
          console.error('Error fetching customer current plan:', error)
        })
        .finally(() => {
          this.isCustomerCurrentPlanLoading = false
        })
    },
    selectPlan(plan) {
      this.selectedPlan = plan
      this.menu = false

      const cards = document.querySelectorAll('.internet-plan-card')
      cards.forEach(card => {
        card.classList.remove('selected-card')
      })

      const selectedCard = document.querySelector(`.internet-plan-card[data-plan-id="${plan.id}"]`)
      if (selectedCard) {
        selectedCard.classList.add('selected-card')
      }
    },
    onPlanChanged() {
      this.$refs.pendingPlanChangeAlertRef.checkForPendingPlanChange()
    },
    onPlanChangeScheduled() {
      this.$refs.scheduledPlanChangeAlertRef.fetchInternetPlanUpdateSchedule()
    },
  },
}
</script>
