<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Select When to Apply the Plan Change</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="emitPlanChange"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  {{ message }}
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="whenToChangePlan"
                    :rules="[
                      v => !!v || 'Required',
                    ]"
                    row
                  >
                    <v-radio
                      label="Now"
                      value="now"
                    ></v-radio>
                    <v-radio
                      label="After Current Plan Ends"
                      value="after_current_plan_ends"
                    ></v-radio>
                    <v-radio
                      label="On Specific Date"
                      value="on_specific_date"
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <div v-show="whenToChangePlan === 'on_specific_date'">
                      <date-time-picker
                        :min-date="minDate"
                        :default-date-time="specificDate"
                        :label="'Specific Date'"
                        @change="specificDate = $event"
                      />
                    </div>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isAgreeButtonLoading"
              type="submit"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import DateTimePicker from '../partials/DateTimePicker.vue'

export default {
  name: 'PlanChangeDialog',
  components: {
    DateTimePicker,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isAgreeButtonLoading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: 'Select When to Apply the Plan Change',
    },
  },
  data() {
    return {
      dialog: false,
      whenToChangePlan: 'now',
      specificDate: moment().format('DD/MM/YYYY h:mm A'),
    }
  },
  computed: {
    minDate() {
      return new Date().toISOString()
    },
  },
  watch: {
    value(newValue) {
      this.dialog = newValue
    },
    dialog(newValue) {
      this.$emit('input', newValue)
    },
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
    },
    emitPlanChange() {
      this.$emit('plan-change', {
        whenToChangePlan: this.whenToChangePlan,
        specificDate: this.specificDate,
      })
    },
  },
}
</script>
