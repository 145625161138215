<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="620"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedDateTime"
        :label="label"
        readonly
        outlined
        v-on="on"
      />
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >
            <span v-if="formattedDateTime">
              Selected: {{ formattedDateTime }}
            </span>
            <span v-else>
              Please select a date and time.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              :min="formattedMinDate"
              :max="formattedMaxDate"
            >
              <template v-slot:day="{ date }">
                {{ date.getDate() }}
              </template>
            </v-date-picker>
          </v-col>
          <v-col cols="6">
            <v-time-picker
              v-model="time"
              format="ampm"
              ampm-in-title
              scrollable
              full-width
            >
              <template v-slot:ampm="{ period }">
                {{ period.toUpperCase() }}
              </template>
            </v-time-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveDateTime"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    label: {
      type: String,
      default: 'Date Time',
    },
    defaultDateTime: {
      type: [String, Date],
      default: null,
    },
    minDate: {
      type: [String, Date],
      default: null,
    },
    maxDate: {
      type: [String, Date],
      default: null,
    },
  },
  data() {
    const defaultDateTimeMoment = this.defaultDateTime
      ? moment(this.defaultDateTime)
      : moment()

    return {
      date: defaultDateTimeMoment.format('YYYY-MM-DD'),
      time: defaultDateTimeMoment.format('HH:mm'),
      showTimePicker: false,
      dialog: false,
    }
  },
  computed: {
    formattedMinDate() {
      return this.minDate ? moment(this.minDate).format('YYYY-MM-DD') : null
    },
    formattedMaxDate() {
      return this.maxDate ? moment(this.maxDate).format('YYYY-MM-DD') : null
    },
    formattedDateTime() {
      if (this.date && this.time) {
        const dateTime = moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm')
        const localDateTime = dateTime.local()

        return localDateTime.isValid() ? localDateTime.format('DD/MM/YYYY h:mm A') : null
      }

      return null
    },
  },
  watch: {
    date() {
      this.showTimePicker = true
    },
    defaultDateTime() {
      const defaultDateTimeMoment = this.defaultDateTime
        ? moment.utc(this.defaultDateTime).local()
        : null

      this.date = defaultDateTimeMoment ? defaultDateTimeMoment.format('YYYY-MM-DD') : null
      this.time = defaultDateTimeMoment ? defaultDateTimeMoment.format('HH:mm') : null
    },
  },
  methods: {
    saveDateTime() {
      this.dialog = false
      this.showTimePicker = false
      this.$emit('change', this.formattedDateTime)
    },
  },
}
</script>
